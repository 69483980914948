import {DOSE_UNIT_CHOICES} from '../@core/dose_unit_choices';
import {SnomedConcept} from '../models/snomed-concept';
import {CodedValue} from '../models/coded-value';
import {VisitBillingCodeSerializer} from '../@core/api.service';

export type IdName = {id: string | number; name: string};
export type NumberIdName = {id: number; name: string};
export type StringIdName = {id: string; name: string};

export function compareByIds(e1, e2): boolean {
    return e1 !== null && e2 !== null && (e1.id || e1) === (e2.id || e2);
}

export function constSetToIdName(constSet: {[keys: string]: string}) {
    return Object.entries(constSet).map(([id, name]) => ({id, name} as StringIdName));
}

export function getDoseUnitChoices() {
    const doseUnitChoices = Object.entries(DOSE_UNIT_CHOICES).map(([key, value]) => ({id: key, name: `${key} - ${value}`}) as StringIdName);
    doseUnitChoices.find(x => x.id === 'unit').name = 'unit';
    return doseUnitChoices;
}

export function searchInCodeAndName(term: string, item: SnomedConcept | CodedValue) {
    return term.split(' ').every(substr => item.name.toLowerCase().includes(substr.toLowerCase()) || item.code.toString().includes(substr));
}

// TODO: find global usage of this function and replace with searchInCodeAndName and searchInCodeAndDescription
// export function searchIn<T>(term: string, item: T, fields: (keyof T)[]) {
//     return term.split(' ').every(substr => fields.some(field => String(item[field]).toLowerCase().includes(substr.toLowerCase())));
// }
export function searchInCodeAndDescription(term: string, item: VisitBillingCodeSerializer) {
    return term.split(' ').every(substr => item.description.toLowerCase().includes(substr.toLowerCase()) || item.code.toString().includes(substr));
}
