export const DOSE_UNIT_CHOICES = {
    AEROSOL: 'Aerosol',
    AEROSOL_FOAM: 'Aerosol, foam',
    AEROSOL_METERED: 'Aerosol, metered',
    AEROSOL_POWDER: 'Aerosol, powder',
    AEROSOL_SPRAY: 'Aerosol, spray',
    BAR_CHEWABLE: 'Bar, chewable',
    BEAD: 'Bead',
    CAPSULE: 'Capsule',
    CAPSULE_COATED: 'Capsule, coated',
    CAPSULE_COATED_PELLETS: 'Capsule, coated pellets',
    CAPSULE_COATED_EXTENDED_RELEASE: 'Capsule, coated, extended release',
    CAPSULE_DELAYED_RELEASE: 'Capsule, delayed release',
    CAPSULE_DELAYED_RELEASE_PELLETS: 'Capsule, delayed release pellets',
    CAPSULE_EXTENDED_RELEASE: 'Capsule, extended release',
    CAPSULE_FILM_COATED_EXTENDED_RELEASE: 'Capsule, film coated, extended release',
    CAPSULE_GELATIN_COATED: 'Capsule, gelatin coated',
    CAPSULE_LIQUID_FILLED: 'Capsule, liquid filled',
    CELLULAR_SHEET: 'Cellular Sheet',
    CLOTH: 'Cloth',
    CONCENTRATE: 'Concentrate',
    CREAM: 'Cream',
    CREAM_AUGMENTED: 'Cream, augmented',
    CRYSTAL: 'Crystal',
    DISC: 'Disc',
    DOUCHE: 'Douche',
    DRESSING: 'Dressing',
    DRUG_DELIVERY_SYSTEM: 'Drug delivery system',
    ELIXIR: 'Elixir',
    EMULSION: 'Emulsion',
    ENEMA: 'Enema',
    EXTRACT: 'Extract',
    FILM: 'Film',
    FILM_EXTENDED_RELEASE: 'Film, extended release',
    FILM_SOLUBLE: 'Film, soluble',
    FOR_SOLUTION: 'For solution',
    FOR_SUSPENSION: 'For suspension',
    FOR_SUSPENSION_EXTENDED_RELEASE: 'For suspension, extended release',
    GAS: 'Gas',
    GEL: 'Gel',
    GEL_DENTIFRICE: 'Gel, dentifrice',
    GEL_METERED: 'Gel, metered',
    GENERATOR: 'Generator',
    GRANULE: 'Granule',
    GRANULE_DELAYED_RELEASE: 'Granule, delayed release',
    GRANULE_EFFERVESCENT: 'Granule, effervescent',
    GRANULE_FOR_SOLUTION: 'Granule, for solution',
    GRANULE_FOR_SUSPENSION: 'Granule, for suspension',
    GRANULE_FOR_SUSPENSION_EXTENDED_RELEASE: 'Granule, for suspension, extended release',
    GUM_CHEWING: 'Gum, chewing',
    IMPLANT: 'Implant',
    INHALANT: 'Inhalant',
    INJECTABLE_FOAM: 'Injectable Foam',
    INJECTABLE_LIPOSOMAL: 'Injectable, liposomal',
    INJECTION: 'Injection',
    INJECTION_EMULSION: 'Injection, emulsion',
    INJECTION_LIPID_COMPLEX: 'Injection, lipid complex',
    INJECTION_POWDER_FOR_SOLUTION: 'Injection, powder, for solution',
    INJECTION_POWDER_FOR_SUSPENSION: 'Injection, powder, for suspension',
    INJECTION_POWDER_FOR_SUSPENSION_EXTENDED_RELEASE: 'Injection, powder, for suspension, extended release',
    INJECTION_POWDER_LYOPHILIZED_FOR_SOLUTION: 'Injection, powder, lyophilized, for solution',
    INJECTION_POWDER_LYOPHILIZED_FOR_SUSPENSION: 'Injection, powder, lyophilized, for suspension',
    INJECTION_POWDER_LYOPHILIZED_FOR_SUSPENSION_EXTENDED_RELEASE: 'Injection, powder, lyophilized, for suspension, extended release',
    INJECTION_SOLUTION: 'Injection, solution',
    INJECTION_SOLUTION_CONCENTRATE: 'Injection, solution, concentrate',
    INJECTION_SUSPENSION: 'Injection, suspension',
    INJECTION_SUSPENSION_EXTENDED_RELEASE: 'Injection, suspension, extended release',
    INJECTION_SUSPENSION_LIPOSOMAL: 'Injection, suspension, liposomal',
    INSERT: 'Insert',
    INSERT_EXTENDED_RELEASE: 'Insert, extended release',
    INTRAUTERINE_DEVICE: 'Intrauterine device',
    IRRIGANT: 'Irrigant',
    JELLY: 'Jelly',
    KIT: 'Kit',
    LINIMENT: 'Liniment',
    LIPSTICK: 'Lipstick',
    LIQUID: 'Liquid',
    LIQUID_EXTENDED_RELEASE: 'Liquid, extended release',
    LOTION: 'Lotion',
    LOTION_SHAMPOO: 'Lotion / shampoo',
    LOTION_AUGMENTED: 'Lotion, augmented',
    LOZENGE: 'Lozenge',
    MOUTHWASH: 'Mouthwash',
    OIL: 'Oil',
    OINTMENT: 'Ointment',
    OINTMENT_AUGMENTED: 'Ointment, augmented',
    PASTE: 'Paste',
    PASTE_DENTIFRICE: 'Paste, dentifrice',
    PASTILLE: 'Pastille',
    PATCH: 'Patch',
    PATCH_EXTENDED_RELEASE: 'Patch, extended release',
    PATCH_EXTENDED_RELEASE_ELECTRICALLY_CONTROLLED: 'Patch, extended release, electrically controlled',
    PELLET: 'Pellet',
    PELLET_IMPLANTABLE: 'Pellet, implantable',
    PILL: 'Pill',
    PLASTER: 'Plaster',
    POULTICE: 'Poultice',
    POWDER: 'Powder',
    POWDER_DENTIFRICE: 'Powder, dentifrice',
    POWDER_FOR_SOLUTION: 'Powder, for solution',
    POWDER_FOR_SUSPENSION: 'Powder, for suspension',
    POWDER_METERED: 'Powder, metered',
    RING: 'Ring',
    RINSE: 'Rinse',
    SALVE: 'Salve',
    SHAMPOO: 'Shampoo',
    SHAMPOO_SUSPENSION: 'Shampoo, suspension',
    SOAP: 'Soap',
    SOLUTION: 'Solution',
    SOLUTION_DROPS: 'Solution / drops',
    SOLUTION_CONCENTRATE: 'Solution, concentrate',
    SOLUTION_FOR_SLUSH: 'Solution, for slush',
    SOLUTION_GEL_FORMING_DROPS: 'Solution, gel forming / drops',
    SOLUTION_GEL_FORMING_EXTENDED_RELEASE: 'Solution, gel forming, extended release',
    SPONGE: 'Sponge',
    SPRAY: 'Spray',
    SPRAY_METERED: 'Spray, metered',
    SPRAY_SUSPENSION: 'Spray, suspension',
    STICK: 'Stick',
    STRIP: 'Strip',
    SUPPOSITORY: 'Suppository',
    SUSPENSION: 'Suspension',
    SUSPENSION_DROPS: 'Suspension / drops',
    SUSPENSION_EXTENDED_RELEASE: 'Suspension, extended release',
    SWAB: 'Swab',
    SYRUP: 'Syrup',
    TABLET: 'Tablet',
    TABLET_CHEWABLE_EXTENDED_RELEASE: 'Tablet, Chewable, Extended Release',
    TABLET_CHEWABLE: 'Tablet, chewable',
    TABLET_COATED: 'Tablet, coated',
    TABLET_DELAYED_RELEASE: 'Tablet, delayed release',
    TABLET_DELAYED_RELEASE_PARTICLES: 'Tablet, delayed release particles',
    TABLET_EFFERVESCENT: 'Tablet, effervescent',
    TABLET_EXTENDED_RELEASE: 'Tablet, extended release',
    TABLET_FILM_COATED: 'Tablet, film coated',
    TABLET_FILM_COATED_EXTENDED_RELEASE: 'Tablet, film coated, extended release',
    TABLET_FOR_SOLUTION: 'Tablet, for solution',
    TABLET_FOR_SUSPENSION: 'Tablet, for suspension',
    TABLET_MULTILAYER: 'Tablet, multilayer',
    TABLET_MULTILAYER_EXTENDED_RELEASE: 'Tablet, multilayer, extended release',
    TABLET_ORALLY_DISINTEGRATING: 'Tablet, orally disintegrating',
    TABLET_ORALLY_DISINTEGRATING_DELAYED_RELEASE: 'Tablet, orally disintegrating, delayed release',
    TABLET_SOLUBLE: 'Tablet, soluble',
    TABLET_SUGAR_COATED: 'Tablet, sugar coated',
    TAPE: 'Tape',
    TINCTURE: 'Tincture',
    TROCHE: 'Troche',
    WAFER: 'Wafer',
    L: 'liter',
    U: 'enzyme unit',
    cL: 'centiliter',
    cal: 'calorie',
    cg: 'centigram',
    cm: 'centimeter',
    cm2: 'square centimeter',
    cm3: 'cubic centimeter',
    dL: 'deciliter',
    dg: 'decigram',
    dm: 'decimeter',
    g: 'gram',
    kcal: 'kilocalorie',
    kg: 'kilogram',
    m: 'meter',
    mL: 'milliliter',
    mg: 'milligram',
    mm: 'millimeter',
    mmol: 'millimole',
    mo: 'month',
    mol: 'mole',
    nL: 'nanoliter',
    ng: 'nanogram',
    pL: 'picoliter',
    pg: 'picogram',
    uL: 'microliter',
    ug: 'microgram',
    umol: 'micromole',
    unit: 'unit',
    unk: 'unknown',
};
